@import '../../../variables.scss';

.container {
  border: 2px solid #cccccc;
  position: relative;
  padding: 30px 20px;
  box-sizing: border-box;
  max-width: 300px;
  margin: auto;
  position: relative;
  color: $text-color;
}

.header {
  padding: 2px 5px;
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  background: $white-color;
  color: var(--main, $dark-blue-color);
  position: absolute;
  top: -14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 53%;
  text-align: center;
}

.content {
  display: inline-block;
}
