
@import '../../variables.scss';

.container {
  text-align: start;
  color: var(--main, #1f357b);
  margin: 0 6px 16px;
}

.currentQuestion {
  font-weight: bolder;
}

.header {
  font-size: $font-size-md;
  color: $text-color;
  flex: 0 0 auto;
  text-align: start;
  font-weight: bold;
  margin-top: 8px;
  user-select: none;
}

.questionNumberContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.difficultyLevelIndicatorContainer {
  margin-left: 12px;
  font-size: 12px;
  color: #545e6b;
  font-weight: 300;
}