@import '../../../variables.scss';
@import '../../../shared.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
}

.imageQuestion {
  display: flex;
  justify-content: center;
}

.tableContainer {
  margin: 0px 0;
  flex: 0 1 auto;
  overflow-y: auto;
}

.table {
  margin: auto;
  border-spacing: 15px;
  width: 100%;
  color: $text-color;
  font-size: $font-size-md;
  max-width: 900px;
}

.table td {
  @extend %noSelect;
  margin: 4px;
  border: none;
  width: 50%;
  text-align: left;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  vertical-align: top;
  cursor: pointer;
  display: flex;
}

.table tr {
  display: flex;
}
.table td span {
  font-weight: 800;
}

.answerBtn {
  background-color: transparent;
  border: 1px solid var(--answer, $blue-color);
  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: left;
  font-weight: inherit;
  font-size: inherit;
  color: var(--answer, $blue-color);
  cursor: pointer;
  font-family: var(--fontFamily, 'Fira Sans', sans-serif) !important;
  display: flex;
}

.answerBtnDesktopEffects:active,
.answerBtnDesktopEffects:hover {
  border-color: var(--tabColor, $gray-color);
  color: var(--tabColor, $gray-color);
}
.answerRow {
  display: flex;
}
.checkbox {
  height: 16px;
  width: 16px;
  accent-color: var(--answer, $blue-color);
}
.answer {
  word-wrap: break-word;
  max-width: 300px;
  margin-left: 8px;
}

td.emptyTd {
  border: none;
}

td.emptyTd:hover {
  cursor: default;
}

@media screen and (max-width: 640px) {
  .table tbody td {
    display: block;
    width: 100%;
    margin: 30px 0;
    box-sizing: border-box;
  }

  .table tbody tr {
    display: block;
  }

  .table {
    border-spacing: 10px;
    padding: 0 10px;
    font-size: $font-size-sm;
  }
}

.sendButton {
  margin: 50px 0;
}
.questionTitle {
  color: $blue-color;
}

.questionAnswer {
  display: flex;
  flex-grow: 1;
  margin-top: 20px;
  flex-direction: column;
}

.questionImage {
  padding-bottom: 35px;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  justify-content: center;
}
.image {
  max-width: 366px;
  max-height: 244px;
}

.imageAnswerList {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding-top: 15px;
  justify-content: center;
  margin-bottom: 60px;
}

.answerContainer {
  width: 272px;
  min-height: 224px;
  border: 1px solid $blue-color;
  margin: 15px 8px;
  background: #fff;
  justify-content: center;
}
.answerContainer:hover {
  border-color: $gray-color;
}

.answerContainer:hover {
  cursor: pointer;
}
.innerAnswerContainer {
  margin: 0 16px 16px 16px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.answerText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1b87e6;
  padding: 16px 6px;
  text-align: left;
  word-wrap: break-word;
}
.answerContainer:hover .answerText {
  color: $gray-color;
}
.answerImg {
  width: 240px;
  height: 160px;
  padding-bottom: 16px;
  object-fit: scale-down;
}
.imageAnswers {
  composes: imageAnswerList;
  justify-content: center;
}

.sendButtonRow {
  margin-bottom: 50px;
}
