.cardSwipeBtnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  width: 64px;
  height: 64px;
  border-radius: 76px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.btn:hover {
  cursor: pointer;
}

.cardSwipeBtnContainer > span {
  display: flex;
  color: #545e6b;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}
