@import '../../../variables.scss';
@import '../../../shared.scss';

.tabRow {
  display: flex;
  margin-bottom: 15px;
  user-select: none;
  position: relative;
}

.tabRow .tab {
  border-bottom: 1px solid #ddd;
  position: absolute;
  width: 100%;
  bottom: 0;
  color: $text-color;
  font-size: 14px;
}

.tabRow .activeTab {
  border-bottom: 1px solid var(--tabColor, $blue-color);
  color: var(--tabColor, $blue-color);
}
.tabRow .tabs {
  padding: 10px;
  font-size: 14px;
  z-index: 1;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  display: flex;
}

.container {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  flex-grow: 1;
  width: 97%;
  padding: 20px 10px 10px 10px;
  overflow: auto;
}

.liveFeedIcon {
  padding-right: 8px;
  height: 16px;
  width: 20px;
}
.waitingMsg {
  text-align: center;
  font-size: 15px;
  margin: 10px;
}
.errorMessage {
  justify-content: center;
  display: flex;
}
@media screen and (max-width: 640px) {
  .tabRow .tab {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    width: 95%;
  }
  .tabRow .activeTab {
    padding: 10px;
    border-bottom: 1px solid var(--tabColor, $blue-color);
    color: var(--tabColor, $blue-color);
    font-size: 14px;
  }
  .container {
    margin: 10px 5px 5px 5px;
    text-align: start;
  }
  .liveFeedIcon {
    padding-right: 8px;
    height: 11px;
    width: 15px;
  }
  .waitingMsg {
    text-align: center;
    font-size: 14px;
    margin: 10px;
  }
}
