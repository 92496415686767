.rankImage {
  display: inline-block;
  vertical-align: bottom;
}
.rankText {
  font-size: 24px;
}

.rankSpan {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  vertical-align: bottom;
  top: -10px;
  left: -20px;
  position: relative;
  color: #505050;
}
.placeText {
  font-size: 16px;
  margin-left: 10px;
}