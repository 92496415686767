@import '../../../../../variables.scss';
@import '../../../../../shared.scss';

.cardText {
  margin: auto 0;
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--main, $main-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 7;
  -webkit-line-clamp: 7;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.alignBottom {
  margin: auto 0 0 0;
}

.swipeGradientOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
