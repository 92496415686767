@import '../../../variables.scss';
@import '../../../shared.scss';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 35px;
}

.questionTitle {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  color: $text-color;
  text-align: left;
  margin: 0 0 20px 0;
}

.textInputContainer {
  align-self: center;
  width: 100%;
}

.textInput {
  border: 1px solid $text-color;
  width: calc(100% - 32px);
  font-size: 18px;
  font-weight: 500;
  color: $text-color;
  overflow: auto;
  height: 150px;
  min-height: 150px;
  max-height: 250px;
  line-height: 25px;
  resize: none;
  background-color: #eeeeee;
  box-sizing: border-box;
  padding: 8px;
  margin: 0 16px;
  font-family: 'Fira sans';
}

.textInput:focus {
  outline: none;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 5%;
  overflow: auto;
}

.textInputAndBtnContainer {
  margin: 5% auto 0 auto;
  width: 80%;
}

.sendButtonAndCharacterRow {
  display: flex;
  justify-content: space-between;
  margin: 15px 16px 0 16px;
}

.textLength {
  display: flex;
  align-items: flex-start;
  color: $text-color;
  font-size: 12px;
}

.sendButton {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: var(--button-text, $white-color);
  background-color: var(--button-background, $blue-color);
  font-weight: bold;
  padding: 5px 25px;
  border: none;
  border-radius: 2px;
}

.hideButton {
  pointer-events: none;
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .questionTitle {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    color: $text-color;
    text-align: left;
    margin: 0 0 10px 0;
  }

  .textInputAndBtnContainer {
    width: 100%;
  }
}
