@import '../../../shared.scss'; 

.commentRow {
  display: flex;
  flex-direction: column;
  margin: 25px 10px 0 10px;
}
.commentRow .comment {
  font-size: 14px;
}
.respondentName {
  font-size: 12px;
  margin-top: 5px;
  font-weight: bold;
  max-width: 500px;
  @include ellipsis
}

@media screen and (max-width: 280px) {
  .respondentName {
    max-width: 200px;
  }
}

@media screen and (min-width:300px) and  (max-width: 540px) {
  .respondentName {
    max-width: 300px;
  }
}
