@import '../../../variables.scss';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin: 16px;
}

.tabContainer {
  display: flex;
  font-size: 14px;
  position: relative;
}

.positionedAbsBottom {
  position: absolute;
  bottom: 0;
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background-color: #ddd;
}

.tab {
  display: flex;
  padding: 5px 20px;
  align-items: center;
}

.tab:hover {
  cursor: pointer;
}

.activeTab {
  border-bottom: 1px solid   var(--tabColor, $blue-color);
  z-index: 1;
  color:  var(--tabColor, $blue-color);
}

.contentContainer {
  margin-top: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.contentHeading {
  font-size: 20px;
  font-weight: 500;
  color:  var(--main,  #1B3380);
  display: inline-block;
  margin: 30px 0 0 40px;
}

.icon {
  margin-right: 10px;
  display: inline-flex;
}

.answerTabTextContent {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  margin: 15px 0 0 40px;
}

.answerContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 16px 0;
}

.answerText {
  color: #545E6B;
  font-size: 16px;
}

.answerMentions {
  color: #9B9B9B;
  font-size: 14px;
  margin-top: 8px;
}

.answerTabContainer {
  background-color: #f5f5f5;
  flex-grow: 1;
}

.wordCloudTabTextContent {
  margin-top: 24px;
}
@media only screen and (max-width: 600px)  {
  .answerTabTextContent {
    width: 100%;
  }
}