@import '../../../variables.scss';
@import '../../../shared.scss';

.incorrectAnswer{
  position: relative;
}

.answerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto;
  background-color: $white-color;
  min-width: 285px;
  max-width: 300px;
  height: 40px;
  font-weight: 500;
  font-size: $font-size-sm;
  text-align: left;
  color: var(--main, $main-color);
  border: 1px solid var(--main, $main-color);
  border-radius: 2px;
  word-wrap: break-word;
}

.correctIconScore {
  color: var(--main, $main-color);
  font-size: 48px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.icon {
  display: flex;
}

.correctIconScore > span:last-child {
  margin-left: 12px;
}

.speedIconScore {
  color: var(--main, $main-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  justify-content: center;
}

.speedText {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.speedText > span:last-child {
  margin-left: 8px;
  font-size: 24px;
  font-weight: 400;
}