@import '../../variables.scss';

.exitLink {
  text-decoration: none;
  color: $gray-color;
}

.exitLink:hover {
  opacity: 0.3;
}

.exitLinkImg {
  position: relative;
  top: 3px;
  color: var(--tabColor, #1b87e6);
}
.exitLinkImg:hover {
  cursor: pointer;
}
