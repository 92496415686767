@import './variables.scss';

.container {
  padding: 15px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-repeat: repeat-x;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-family: var(--fontFamily, 'Fira Sans', sans-serif);
  background-color: $white-color;
}

.innerContainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.companyLogoAndBrand {
  position: absolute;
  bottom: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  left: 0;
}