@import '../../variables.scss';

.homeLink {
  text-decoration: underline;
  color: $gray-color;
  font-weight: 600;
}

.homeLink:link:hover {
  opacity: 0.7;
}
