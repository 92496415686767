$submit-btn-color: #027ddd;
$green-light-color: #87d0c2;
$green-dark-color: #7abcb0;
$green-darker-color: #65807c;
$purple-color: #cc749b;
$gray-color: #423f3f;
$text-color: #545e6b;
$little-light-gray-color: #e8e8e8;
$light-gray-color: #f3f3f3;
$blue-color: #027ddd;
$dark-blue-color: #1f357b;

$white-color: #ffffff;
$answer-border-color: #acdce5;
$lightblack-color: #555;
$orange-light-color: #dca276;
$orange-dark-color: #d09b74;
$question-display-blue: #78c6d5;

$main-color: #1b3380;

$font-size-xs: 12px;
$font-size-sm: 16px;
$font-size-md: 18px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-2xl: 28px;
$font-size-3xl: 35px;

$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;
