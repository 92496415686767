.showTeam {
  margin: 20px 0;
}
.waitMessage {
  margin-bottom: 200px;
  text-align: center;
}
.games {
  margin-top: 20px;
}
.playGameMessage {
  font-size: 14px;
  margin-bottom: 10px;
}
