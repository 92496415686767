@import '../../../variables.scss';
@import '../../../shared.scss';

.container {
  @extend %slideIn !optional;
  width: 100%;
}

.message {
  font-size: $font-size-xl;
  color: $white-color;
}

.score {
  color: var(--main, $dark-blue-color);
  font-size: 50px;
  font-weight: $font-weight-bold;
}

.resultIcon {
  color: var(--main, $dark-blue-color);
  text-align: center;
}
.incorrectAnswer {
  height: 100px;
  position: relative;
}
.resultIcon > img,
.resultIcon .score {
  display: inline-block;
  vertical-align: middle;
}

.resultIcon .score {
  padding-left: 5px;
}

.resultIcon img {
  padding: 0;
}
.correctAnswerIconAndScore {
  color: var(--main, $dark-blue-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightAnswerSvg {
  color: var(--main, #1f357b);
}
.plusIconSvg {
  color: var(--main, #1f357b);
}
.shake {
  @extend %shakeAnimation;
  color: var(--main, #1f357b);
}

.speed {
  color: var(--main, $dark-blue-color);
  margin-top: 50px;
  text-align: center;
}
.plusIconAndScore,
.speedIconAndScoreText {
  display: flex;
  justify-content: center;
  align-items: center;
}
.speedScore {
  padding-left: 5px;
  font-size: 35px;
  vertical-align: middle;
  font-weight: $font-weight-bold;
}

.speed img,
.speed .speedText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
}

.speedText {
  font-size: 25px;
}
.speedSvg {
  color: var(--main, $dark-blue-color);
}

.answerDivContainer {
  margin: 20px auto;
}

.answerDiv {
  padding: 10px;
  margin: 7px auto;
  background-color: $white-color;
  min-width: 150px;
  max-width: 300px;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  text-align: left;
  color: var(--main, $text-color);
  border: 1px solid var(--main, $text-color);
  border-radius: 2px;
  word-wrap: break-word;
}

.inverseAnswerDiv {
  composes: answerDiv;
  color: $white-color;
  background-color: var(--main, $dark-blue-color);
}

.correctAnswerLabel {
  margin: 30px auto 10px auto;
  font-weight: $font-weight-bold;
  text-align: center;
  color: var(--main, $dark-blue-color);
}

.resultIcon .plusIcon {
  width: 24px;
  color: $white-color;
  position: relative;
  right: -4px;
}

.speed .plusIcon {
  width: 20px;
  position: relative;
  top: -6px;
  right: -6px;
}

.plusIcon {
  display: inline-block;
  vertical-align: middle;
}
.selectedAnswerContainer {
  width: 272px;
  height: 224px;
  border: 1px solid #1b87e6;
  margin: 15px auto;
}
.innerSelectedAnswerContainer {
  margin: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
}
.answerText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1b87e6;
  padding: 16px 0;
  text-align: left;
  @include ellipsis;
}
.answerImg {
  width: 240px;
  height: 160px;
  object-fit: scale-down;
}
.pollVoteImage {
  color: var(--main, #1b3380);
}
.difficultyLevelIndicatorFlag {
  width: 21px;
  height: 21px;
  margin-left: 8px;
}
