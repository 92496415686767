@import '../../../variables.scss';

.pinInput input[type='text'] {
  width: 28px;
  padding: 5px 5px;
  margin: 4px;
  outline: none;
  background-color: $light-gray-color;
  color: $text-color;
  box-sizing: border-box;
  border: none;
  font-size: $font-size-2xl;
  font-weight: $font-weight-bold;
  text-align: center;
  border-radius: 0;
  border-bottom: 1px solid #cccccc;
}

.pinInput input[type='text']:focus {
  border-bottom: 1px solid $blue-color;
}