@import '../../variables.scss';
@import '../../shared.scss';

.container {
  margin-top: 15px;
  position: relative;
  width: 100%;
  text-align: center;
}

.container input[type='email'] {
  border: none;
  border-bottom: 1px solid $lightblack-color;
  display: block;
  width: 100%;
  box-sizing: border-box;
  max-width: 343px;
  margin: auto;
  padding: 7px 7px 7px 30px;
  background-color: $white-color;
  color: $gray-color;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  text-align: center;
  background-image: url(/images/icons_email.svg);
  background-repeat: no-repeat;
  background-position: 4px 11px;
  background-size: 15px 15px;
  border-radius: 0;
}

.container input[type='email']:focus {
  outline: none;
  border: none;
}

.container input[type='email']:focus::placeholder {
  color: transparent;
}

.container .line {
  height: 1px;
  width: 0px;
  background-color: $white-color;
  display: inline-block;
  transition: 0.3s width ease-in-out;
  position: relative;
  top: -15px;
  max-width: 343px;
}

.container input[type='email']:focus + .line {
  width: 100%;
  background-color: $blue-color;
}

.container input[type='email']:focus {
  background-image: url(/images/icons_email_blue.svg);
}

.container {
  @include placeholder-shared() {
    color: $lightblack-color;
  }
}
