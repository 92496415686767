.cardAnswerTextRow {
  display: flex;
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  z-index: 1;
  max-height: 60px;
}

.cardAnswerTextRow span:last-child {
  margin-left: auto;
}

.padding {
  padding: 16px;
}

.transitionOpacity {
  transition: opacity 0.5s ease-in;
}

.hidden {
  opacity: 0;
}
