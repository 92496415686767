@import '../../variables.scss';

.btn {
  background-color: var(--button-background, #1b87e6);
  color: var(--button-text, #fff);
  border: none;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  display: block;
  margin: auto;
  padding: 10px;
  width: 100%;
  max-width: 343px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px, rgba(0, 0, 0, 0.22) 0px 3px 3px;
}

.btn:disabled {
  opacity: 0.8;
  background-color: #9b9b9b;
  box-shadow: none;
}

.btn:hover {
  cursor: pointer;
}

.btn:disabled:hover {
  cursor: not-allowed;
}
