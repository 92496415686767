.container {
  display: flex;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.innerContainer {
  display: flex;
  flex-direction: column;
}
.backButton {
  position: absolute;
  top: 15px;
  color: #1b87e6;
  cursor: pointer;
  left: 20px;
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
}
.backButton:hover {
  background: #f1f1f1;
  color: #545e6b;
}
.rightIcon {
  color: #909090;
}
.backButton:hover .rightIcon {
  color: #555;
}
.backButtonText {
  margin-left: 8px;
}
.title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #1b3380;
  margin: 8px 0 16px 0;
  text-transform: capitalize;
}
.title span {
  font-size: 12px;
  line-height: 24px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #545e6b;
}

.questionsWithControls {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.questionContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 260px;
  height: 300px;
  overflow: hidden;
}

.questionBlockContainer {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  transition: 1s ease-in-out all;
  width: 100%;
  height: 100%;
}

.questionBlock {
  background-color: #f5f5f5;
  font-size: 14px;
  color: #545e6b;
  text-align: left;
  padding: 8px;
  line-height: 28px;
  margin: 0 4px;
  min-width: 100%;
}

.currentQuestionIndex {
  font-weight: 500;
}
.previous {
  width: 32px;
  background-color: #edf6fd;
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 300px;
}

.next {
  width: 32px;
  background-color: #edf6fd;
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 300px;
  margin-left: 5px;
}

.next:hover,
.previous:hover {
  cursor: pointer;
}

.previousIcon {
  margin-left: 10px;
  color: #545e6b;
}

.nextIcon {
  margin-left: 10px;
  color: #545e6b;
}

.answer {
  color: #1b3380;
  line-height: 32px;
  font-weight: 500;
  margin-left: 25px;
}
.characterOrder {
  margin-right: 5px;
}
.buttonRow {
  margin: 0;
}
.useThisTemplate {
  text-decoration: none;
}
.templateTypeIconQuiz {
  margin-right: 5px;
  position: absolute;
  margin-left: -27px;
  margin-top: 5px;
}
.templateTypeIconPoll {
  margin-right: 5px;
  position: absolute;
  margin-left: -20px;
  margin-top: 5px;
}
