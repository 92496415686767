@import '../../variables.scss';
@import '../../shared.scss';

.leaderboard {
  width: 100%;
  color: $text-color;
  font-size: $font-size-md;
  font-weight: 500;
  border-spacing: 0;
  max-width: 500px;
  margin: 35px;
}

@media only screen and (max-width: 400px) {
  .leaderboard {
    margin: 25px 0;
  }
}

.leaderboard tbody tr td {
  padding-bottom: 5px;
  padding-top: 5px;
}

.leaderboard tbody tr td:first-child {
  width: 5%;
  text-align: left;
}
.leaderboard tbody tr td:nth-child(3) {
  width: 10%;
  text-align: left;
  padding-right: 15px
}

.leaderboard tbody tr td:nth-child(2) {
  text-align: left;
  max-width: 150px;
  @include ellipsis;
}

.boldRow {
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  color: #545e6b;
  background-color: #f5f5f5;
  height: 40px
}
.normalRow {
  opacity: .7;
  height: 40px
}
.number {
  padding: 5px 12px;
}
