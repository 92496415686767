.dropdown {
  position: relative;
}

/* Hide the dropdown's content by default */
.dropdownContent {
  /* Position it right below the trigger element */
  left: 0;
  position: absolute;
  top: 100%;

  /* It should be on the top of other elements */
  background-color: #fff;
  z-index: 9999;

  /* Size */
  min-width: 200px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.dropDownBtn {
  background-color: #fff;
  border: none;
  color: #545e6b;
  font-size: 14px;
  padding: 10px;
}

.dropDownBtn:hover {
  background-color: #e8e8e8;
}

.dropdownIcon {
  padding-left: 10px;
}

.leftIcon {
  padding-right: 10px;
}

.leftColor {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 6px;
}
