.cardSwipeBtnMobile {
  display: none;
  align-self: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  width: 98%;
  margin: 0 6px 26px;
}

.leftSide,
.rightSide {
  display: flex;
  align-items: center;
}

.leftSide {
  color: #ff7681;
}

.rightSide {
  color: #3fca7f;
  margin-left: auto;
}

.iconContainer {
  display: flex;
}

.leftSwipeBtnTextMobile {
  margin-left: 8px;
}

.rightSwipeBtnTextMobile {
  margin-right: 8px;
}

@media only screen and (max-width: 768px) {
  .cardSwipeBtnMobile {
    display: flex;
  }
}

@media only screen and (max-width: 400px) and (max-height: 700px) {
  .cardSwipeBtnMobile {
    margin: 0 6px 18px;
  }
}
