@import '../../../variables.scss';

.thankYouText {
  font-size: 24px;
  color: $lightblack-color;
  margin-bottom: 10px;
}

.linkToResultsText {
  font-size: 14px;
  color: $lightblack-color;
  line-height: 20px;
  margin-top: 5px;
}
.templatesBlock {
  height: 80vh;
  overflow: auto;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.bottom {
  position: relative;
  bottom: 0px;
}

.topTemplatesText {
  font-size: 24px;
  color: #1b3380;
  font-weight: 500;
  line-height: 36px;
  margin: 10px 0 20px;
}
.template {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  text-align: left;
  margin: 8px;
  max-width: 330px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .template {
    max-width: 300px;
  }
}
.template:hover {
  background: #edf6fd;
}
.title {
  color: #1b3380;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.metaDescription {
  font-size: 12px;
  color: #545e6b;
  padding: 8px 0;
  line-height: 16px;
}
.templateFooter {
  display: flex;
  justify-content: space-between;
}
.templateType {
  color: #1b3380;
  font-size: 12px;
  font-weight: 400;
}
.quizIconRow {
  display: flex;
  align-items: flex-end;
}
.templateTypeIconQuiz {
  margin-right: 5px;
  height: 17px;
  width: 20px;
}
.templateTypeIconPoll {
  margin-right: 5px;
  height: 15px;
  width: 16px;
}
.questionCount {
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  background: #eee;
  color: #545e6b;
  min-width: 72px;
}
.form {
  width: 100%;
}

.signUpText {
  line-height: 27px;
  width: 70%;
  margin: auto;
  font-size: 16px;
  color: $lightblack-color;
  margin-bottom: 5px;
}

.learnMoreText {
  color: $lightblack-color;
  margin-top: 16px;
}

.goToLivePollsLink {
  text-decoration: none;
  color: #1b87e6;
}

.goToLivePollsLink:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media (min-height: 900px) {
  .templatesBlock {
    height: 75vh;
  }
}
