.upVoteCount {
  font-size: 14px;
  margin-left: 5px;
  margin-right: 15px;
}
.downVoteCount {
  font-size: 14px;
  margin-left: 5px;
}
.container {
  margin: 7px 10px;
}
.icon {
  height: 16px;
}
.icon:hover {
  cursor: pointer;
}

@media only screen and (max-width: 400px) {
  .icon {
    height: 18px;
  }
}
