@import '../../../variables.scss';

.container {
  text-align: center;
  margin-bottom: 60px;
}

.title {
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  margin-top: 20px;
}

.subtitle {
  padding-top: 20px;
  display: block;
  color: $green-darker-color;
  font-weight: $font-weight-bolder;
}
