.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}
.circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1b87e6;
  animation: showCircleAnimate 0.25s ease-in forwards;
}
.circleContent {
  animation: moveCircle 1.2s forwards;
  animation-delay: 1.25s;
  position: absolute;
  top: 50%;
  left: 50%;
}
.innerCircle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1b87e6;
  animation: showInnerCircleAnimate 0.5 ease forwards;
  animation-delay: 0.25s;
}

.line {
  display: inline-block;
  width: 8px;
  height: 0;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: calc(50% + 4px);
  transform-origin: top center;
}

.lineBottomRight {
  transform: rotate(-45deg);
  animation: lineAnimate 0.4s ease forwards;
  animation-delay: 0.4s;
}

.lineBottomLeft {
  transform: rotate(45deg);
  animation: lineAnimate 0.4s ease forwards;
  animation-delay: 0.4s;
}
.lineTopLeft {
  transform: rotate(135deg);
  animation: lineAnimate 0.4s ease forwards;
  animation-delay: 0.4s;
}
.lineTopRight {
  transform: rotate(225deg);
  animation: lineAnimate 0.4s ease forwards;
  animation-delay: 0.4s;
}

@keyframes lineAnimate {
  0% {
    height: 10px;
  }
  100% {
    height: 25px;
  }
}

@keyframes showCircleAnimate {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(800%);
  }
}

@keyframes showInnerCircleAnimate {
  to {
    background-color: #fff;
  }
}
@keyframes moveCircle {
  10% {
    transform: rotate(-15deg);
  }
  20% {
    transform: rotate(15deg);
  }
  30% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-15deg);
  }
  90% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
