.container {
  flex-grow: 1;
  width: 100%;
  margin-top: 25px;
}
.questionImage {
  padding-bottom: 35px;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  justify-content: center;
}