.progress {
  position: fixed;
  width: 100%;
  top: 0;
  margin-left: -15px;
  width: 100%;
  background-color: var(---light-main, #c4e2fc);
  height: 5px;
}

.progressbar {
  height: 100%;
  background-color: var(--main, #1b87e6);
  width: 0%;
  transition: width 60ms linear;
}

@media only screen and (min-width: 1224px) {
  .progress {
    max-width: 980px;
  }
}
