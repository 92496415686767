.showTeam {
  margin: 10px 0;
}
.startProfileQuestionBtn {
  width: 100%;
}
.teamInfo {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
}
.teamName {
  text-align: center;
  padding: 4px 32px;
  border-radius: 80000px;
  color: #fff;
  margin-top: 8px;
}
.team {
  font-size: 12px;
}
.dropdown {
  margin-top: 4px;
}
.startProfileQuestionText {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 48px 0;
  display: grid;
  color: #545E6B;
}
