@import '../../../variables.scss';
@import '../../../shared.scss';
.container {
  height: 100vh;
}
.teams {
  margin-bottom: 50px;
}
.imageDiv {
  margin-top: 50px;
  margin-bottom: 50px;
}

.imageDiv img {
  display: inline-block;
  vertical-align: bottom;
}
.rankText {
  font-size: 24px;
}

.rankSpan {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  vertical-align: bottom;
  top: -30px;
  left: -15px;
  position: relative;
  color: #505050;
}
.placeText {
  font-size: 16px;
  margin-left: 10px;
}
.rightAnswersCountAndSpeedRank {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.rightAnswersCount,
.speedRankContainer {
  font-size: 24px;
  color: var(--main, #1b3380);
}
.count {
  color: #555;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.hoverIcon {
  width: 16px;
  top: -8px;
  left: 4px;
  position: relative;
}
.speedRank {
  color: #555;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.count span {
  font-weight: bold;
  font-size: 24px;
}
.leaderboard {
  display: flex;
  width: 100%;
  justify-content: center;
}
.form {
  width: 100%;
}
.shakeInput {
  animation: shake 0.4s;
  animation-iteration-count: 3;
}
.mostPopularTemplate {
  position: absolute;
  bottom: 60px;
  cursor: pointer;
  color: #1b87e6;
  font-size: 14px;
  width: 100%;
  left: 0;
}
.downArrow {
  margin-top: 10px;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-3px, 0px);
  }

  50% {
    transform: translate(3px, 0);
  }

  75% {
    transform: translate(-3px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}
