@import '../../../variables.scss';
@import '../../../shared.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  margin-top: 35px;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 80vh;
  width: 100%;
}

.textInputContainer {
  align-self: center;
  width: 300px;
}
.answerLimit {
  color: #9b9b9b;
  font-size: 14px;
  text-align: left;
  margin-bottom: 8px;
  padding: 8px 0;
}

.textInput {
  width: 100%;
  border: 1px solid #9b9b9b;
  font-weight: 500;
  color: #555;
  overflow: auto;
  height: 50px;
  resize: none;
  background-color: #eeeeee;
  box-sizing: border-box;
  padding: 16px 8px;
  font-family: 'Fira sans';
  font-size: 12px;
  border-radius: 4px;
}
.textLength {
  display: flex;
  color: $text-color;
  font-size: 12px;
  justify-content: flex-end;
}

.btn {
  width: 250px;
  margin-top: 15px;
}
.answerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 170px);
  padding-bottom: 3%;
}
