@import '../../../variables.scss';
@import '../../../shared.scss';

.leaderboard {
  width: 100%;
  color: $text-color;
  font-size: $font-size-md;
  font-weight: 500;
  border-spacing: 0;
  max-width: 500px;
  margin: 35px;
}

@media only screen and (max-width: 400px) {
  .leaderboard {
    margin: 25px 0;
  }
}

.leaderboard tbody tr td {
  padding-bottom: 5px;
  border-bottom: 1px solid $text-color;
  padding-top: 5px;
}

.leaderboard tbody tr td:first-child {
  width: 5%;
  text-align: left;
}
.leaderboard tbody tr td:nth-child(2) {
  width: 10%;
  text-align: left;
}

.leaderboard tbody tr td:nth-child(3) {
  text-align: left;
  max-width: 150px;
  @include ellipsis;
}

.boldRow {
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  color: var(--main, $dark-blue-color);
}
.arrowUp {
  color: var(--main, $dark-blue-color);
}
.arrowDown {
  color: var(--main, $dark-blue-color);
}

.number {
  padding: 5px 12px;
}

.message {
  display: flex;
  justify-content: center;
  color: var(--text-color, #555);
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.message1 {
  font-size: 18px;
}
.message2 {
  font-size: 14px;
  text-align: center;
}
.leaderboardImg {
  justify-content: center;
  display: flex;
  color: var(--main, $dark-blue-color);
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
  width: 100%;
}
