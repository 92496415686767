@import '../../variables.scss';
@import '../../shared.scss';

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white-color url('../../images/Hands_s.svg') repeat-x bottom;
  height: 100%;
  position: relative;
}
.container img {
  width: 160px;
  margin: 50px 0;
}
.enterPinLabel {
  padding: 20px;
}
.submitBtnContainer {
  margin: 0 30px;
  width: 100%;
  text-align: center;
}
.container {
  @include placeholder-shared() {
    color: $lightblack-color;
  }
}
.shakeInputPin {
  animation: shake 0.4s;
  animation-iteration-count: 3;
}
.errorMsgContainer {
  height: 18px;
  margin-top: 16px;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}
@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-3px, 0px);
  }
  50% {
    transform: translate(3px, 0);
  }
  75% {
    transform: translate(-3px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.alertIcon {
  height: 18px !important;
  width: 18px !important;
  margin: 0 0 -3px !important;
}
.errorMsgTextColor {
  color: #cc0000;
  font-size: 16px;
}
.sessionName {
  color: $text-color;
  font-weight: $font-weight-bold;
}
