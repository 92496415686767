body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  color: var(--text-color, #545e6b);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html,
#root,
#root > div {
  height: 100%;
}

button,
textarea {
  font-family: var(--fontFamily, 'Fira Sans', sans-serif);
}

body {
  background-color: #f2f2f2;
}

.page-enter {
  transform: translate(100%);
}

.page-enter.page-enter-active {
  transform: translate(0%);
  transition: transform 1s ease-in-out;
}

.page-leave {
  transform: translate(0%);
}

.page-leave.page-leave-active {
  transform: translate(-100%);
  transition: transform 1s ease-in-out;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */
