@font-face {
  font-family: 'Verdana Pro';
  src: url('fonts/Verdana-Pro/562fa31bba08b3f71cb71257ddb880d5.eot');
  src: url('fonts/Verdana-Pro/562fa31bba08b3f71cb71257ddb880d5.eot')
      format('embedded-opentype'),
    url('fonts/Verdana-Pro/562fa31bba08b3f71cb71257ddb880d5.woff2')
      format('woff2'),
    url('fonts/Verdana-Pro/562fa31bba08b3f71cb71257ddb880d5.woff')
      format('woff'),
    url('fonts/Verdana-Pro/562fa31bba08b3f71cb71257ddb880d5.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avant Garde';
  src: url('fonts/Avant-Garde/98bf0b578cf58b90a05f8bb6e590c6a1.eot');
  src: url('fonts/Avant-Garde/98bf0b578cf58b90a05f8bb6e590c6a1.eot')
      format('embedded-opentype'),
    url('fonts/Avant-Garde/98bf0b578cf58b90a05f8bb6e590c6a1.woff2')
      format('woff2'),
    url('fonts/Avant-Garde/98bf0b578cf58b90a05f8bb6e590c6a1.woff')
      format('woff'),
    url('fonts/Avant-Garde/98bf0b578cf58b90a05f8bb6e590c6a1.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Georgia';
  src: url('fonts/Georgia/7dca09e227fdfe16908cebb4244589e4.eot');
  src: url('fonts/Georgia/7dca09e227fdfe16908cebb4244589e4.eot')
      format('embedded-opentype'),
    url('fonts/Georgia/7dca09e227fdfe16908cebb4244589e4.woff2') format('woff2'),
    url('fonts/Georgia/7dca09e227fdfe16908cebb4244589e4.woff') format('woff'),
    url('fonts/Georgia/7dca09e227fdfe16908cebb4244589e4.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tahoma';
  src: url('fonts/Tahoma/cd0381aa3322dff4babd137f03829c8c.eot');
  src: url('fonts/Tahoma/cd0381aa3322dff4babd137f03829c8c.eot')
      format('embedded-opentype'),
    url('fonts/Tahoma/cd0381aa3322dff4babd137f03829c8c.woff2') format('woff2'),
    url('fonts/Tahoma/cd0381aa3322dff4babd137f03829c8c.woff') format('woff'),
    url('fonts/Tahoma/cd0381aa3322dff4babd137f03829c8c.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Courier New Regular';
  src: url('fonts/Courier-New/72c75860d89e91a517dfb1e7d308c180.eot');
  src: url('fonts/Courier-New/72c75860d89e91a517dfb1e7d308c180.eot')
      format('embedded-opentype'),
    url('fonts/Courier-New/72c75860d89e91a517dfb1e7d308c180.woff2')
      format('woff2'),
    url('fonts/Courier-New/72c75860d89e91a517dfb1e7d308c180.woff')
      format('woff'),
    url('fonts/Courier-New/72c75860d89e91a517dfb1e7d308c180.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}
