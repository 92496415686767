.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #545E6B;
}
.teamName {
  text-align: center;
  padding: 16px 40px;
  border-radius: 80000px;
  color: #fff;
  margin-top: 8px;
  font-weight: bolder;
  font-size: 18px;
}
.team {
  font-size: 12px;
}
