.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image {
  width: 128px;
  color: var(--main, #1b3380);
}
.textDiv {
  font-size: 16px;
  display: grid;
  margin-top: 45px;
}

.textDiv span {
  align-items: center;
  justify-content: center;
  display: flex;
}
