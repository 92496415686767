@import '../../variables.scss';
@import '../../shared.scss';

.container {
  padding: 15px;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-repeat: repeat-x;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-family: var(--fontFamily, 'Fira Sans', sans-serif);
  background-color: $white-color;
}

.homeLink {
  display: flex;
  justify-content: flex-end;
  right: 8px;
  top: 8px;
}

.innerContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  @extend %slideIn !optional;
  text-align: center;
  width: 100%;
}
.thankYouContainer {
  height: 100vh;
}

@media only screen and (min-height: 900px) {
  .thankYouContainer {
    height: 90vh;
  }
}