@import '../../shared.scss';
@import '../../variables';

.container {
  @extend %container-shared;
  @extend %slideIn !optional;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.container img {
  margin: auto;
}
.timeUpAnimation {
  height: 120px;
}
.timeUpLabel {
  margin-top: 20px;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
}

.waitingMessage {
  font-size: 22px;
  color: var(--text-color, $gray-color);
  margin-top: 20px;
}

.timer {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
