.cardImgBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
  background: #fff;
}

.overlayForText {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  border-radius: 16px;
  background: linear-gradient(
    360deg,
    #000000 -12.93%,
    rgba(0, 0, 0, 0.374237) 46.55%,
    rgba(0, 0, 0, 0) 97.26%
  );
  display: flex;
  color: #fff;
  text-align: left;
  padding: 16px;
  box-sizing: border-box;
  font-size: 24px;
  line-height: 36px;
  align-items: flex-end;
}

.overlayForText > span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swipeGradientOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
