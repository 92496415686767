.darkBackground {
  background-color: #ddd;
}

.visible {
  display: block;
  object-fit: cover;
}

.hidden {
  display: none;
}
