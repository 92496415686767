@import '../../shared.scss';
@import '../../variables.scss';

.container {
  @extend %container-shared;
  @extend %slideIn !optional;
  text-align: center;
  position: relative;
  height: 77vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}

.waitingMessage {
  font-size: $font-size-xl;
  color: $white-color;
}

.timeUpLabel {
  margin-top: 20px;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
}

.timer {
  @extend %shakeAnimation;
  backface-visibility: hidden;
  perspective: 1000px;
}

.answerSubmittedContainer {
  @extend %slideIn !optional;
  width: 100%;
  font-size: $font-size-xl;
  color: $white-color;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.multiselectContainer {
  @extend %slideIn !optional;
  width: 100%;
  font-size: $font-size-xl;
  color: $white-color;
  height: 77vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50px;
}
.votedImage {
  margin-bottom: 18px;
  color: var(--main, #1b3380);
}

.answerSubmittedMessage {
  padding: 10px;
  margin: 7px auto;
  background-color: $white-color;
  min-width: 150px;
  max-width: 300px;
  font-weight: $font-weight-bold;
  font-size: $font-size-md;
  text-align: left;
  color: var(--main, $text-color);
  border: 1px solid var(--main, $text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  word-wrap: break-word;
}
.message {
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  color: #a4a4a4;
  position: relative;
  bottom: 10px;
}

.selectedAnswerContainer {
  width: 272px;
  height: 224px;
  border: 1px solid #1b87e6;
  margin: 15px auto;
}
.innerSelectedAnswerContainer {
  margin: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
}
.answerText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1b87e6;
  padding: 16px 0;
  text-align: left;
  @include ellipsis;
}
.imageAnswerText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: var(--main, $text-color);
  padding-bottom: 16px;
  text-align: left;
  @include ellipsis;
}
.answerImg {
  width: 240px;
  height: 160px;
  object-fit: scale-down;
}
.refreshMessage {
  display: flex;
  justify-content: center;
}
.hereLink {
  padding: 0 5px;
}
