@import './variables.scss';

%container-shared {
  flex-grow: 1;
  width: 100%;
}

%noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

%slideIn {
  /* animation-duration: 1s;
  animation-name: slidein; */
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 200%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

%shakeAnimation {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@mixin placeholder-shared() {
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @content;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    @content;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }

  input::placeholder {
    @content;
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}