.teamInfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.teamName {
  text-align: center;
  padding: 4px 32px;
  border-radius: 80000px;
  color: #fff;
  margin-top: 8px;
}
.team {
  font-size: 12px;
}
.dropdown {
  margin-top: 4px;
}
.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 12px;
  color: #545e6b;
}
.option {
  padding: 8px;
  text-align: left;
}

.option:hover {
  background-color: #eee;
}
