.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #1b87e6;
  position: relative;
  animation: showCircleAnimate 0.25s ease-in forwards;
}
.score {
  color: #1b3380;
  font-weight: bold;
  position: absolute;
  left: 20px;
  top: 5px;
  z-index: 0;
  font-size: 36px;
  animation: scoreAnimate 1.3s ease-in-out 1.2s forwards;
}
.speed {
  color: #1b3380;
  font-weight: bold;
  position: absolute;
  left: 20px;
  top: 5px;
  z-index: 0;
  font-size: 36px;
  animation: speedAnimate 1.3s ease-in-out 3.2s forwards;
}
.scoreOverlay {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b87e6;
  z-index: 1;
}
.speedOverlay {
  visibility: hidden;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b87e6;
  z-index: 3;
}
.image {
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.speedImage {
  width: 10px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% + 20px);
  transform: translate(-50%, -50%);
  z-index: 3;
  visibility: hidden;
  animation: speedDisplay 0.5s forwards;
  animation-delay: 3.2s;
}
.imageOverlay {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #1b87e6;
  z-index: 3;
  animation: imageOverlayAnimate 0.5s ease-in-out 0.5s forwards;
}
.speedImageOverlay {
  width: 0;
  height: 0;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 20px;
  background-color: #1b87e6;
  z-index: 3;
  animation: speedImageOverlayAnimate 0.25s ease-in 2.5s forwards;
}
.imageCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 23px);
  /* animation: imageCircleAnimate 0.5s ease-in-out 1s; */
  opacity: 1;
  z-index: 3;
}
.speedImageCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: calc(50% + 10px);
  animation: speedCircleAnimate 0.5s ease-in-out 1s;
  z-index: 3;
}
.firework {
  display: inline-block;
  width: 8px;
  height: 30px;
  border-radius: 4px;
  background-color: #1b87e6;
  position: absolute;
  left: calc(50% - 4px);
  top: 50%;
  transform-origin: top center;
}
.fireworkBottomRight {
  transform: rotate(-45deg);
  animation: fireworkAnimateBottomRight 0.5s ease-in;
  animation-delay: 0.6s;
}

.fireworkBottomLeft {
  transform: rotate(45deg);
  animation: fireworkAnimateBottomLeft 0.5s ease-in;
  animation-delay: 0.6s;
}

.fireworkTopLeft {
  transform: rotate(135deg);
  animation: fireworkAnimateTopLeft 0.5s ease-in;
  animation-delay: 0.6s;
}
.fireworkTopRight {
  transform: rotate(225deg);
  animation: fireworkAnimateTopRight 0.5s ease-in;
  animation-delay: 0.6s;
}
.fireworkBottom {
  transform: rotate(180);
  animation: fireworkAnimateBottom 1s ease-in;
}
.fireworkTop {
  transform: rotate(180deg);
  animation: fireworkAnimateTop 1s ease-in;
}

.fireworkRight {
  transform: rotate(90deg);
  animation: fireworkAnimateRight 1s ease-in;
}
.fireworkLeft {
  transform: rotate(270deg);
  animation: fireworkAnimateLeft 1s ease-in;
}
@keyframes fireworkAnimateBottomRight {
  0% {
    left: 50%;
    top: 50%;
  }
  50% {
    height: calc(50% + 10px);
    width: 8px;
  }
  75% {
    height: calc(50% + 5px);
    width: 8px;
  }
  100% {
    height: calc(50%);
    width: 8px;
  }
}

@keyframes fireworkAnimateBottomLeft {
  0% {
    left: 50%;
    top: 50%;
  }
  50% {
    height: calc(50% + 10px);
    width: 8px;
  }
  75% {
    height: calc(50% + 5px);
    width: 8px;
  }
  100% {
    height: calc(50%);
    width: 8px;
  }
}

@keyframes fireworkAnimateTopLeft {
  0% {
    left: 50%;
    top: 50%;
  }
  50% {
    height: calc(50% + 10px);
    width: 8px;
  }
  75% {
    height: calc(50% + 5px);
    width: 8px;
  }
  100% {
    height: calc(50%);
    width: 8px;
  }
}

@keyframes fireworkAnimateTopRight {
  0% {
    left: 50%;
    top: 50%;
  }
  50% {
    height: calc(50% + 10px);
    width: 8px;
  }
  75% {
    height: calc(50% + 5px);
    width: 8px;
  }
  100% {
    height: calc(50%);
    width: 8px;
  }
}

@keyframes fireworkAnimateTop {
  0% {
    left: 50%;
    top: 50%;
  }
  75% {
    left: 50%;
    top: -20%;
  }
  100% {
    height: 4px;
    left: 50%;
    top: -20%;
  }
}

@keyframes fireworkAnimateBottom {
  0% {
    left: 50%;
    top: 50%;
  }
  75% {
    left: 50%;
    top: 120%;
  }
  100% {
    height: 4px;
    left: 50%;
    top: 120%;
  }
}
@keyframes fireworkAnimateRight {
  0% {
    left: 50%;
    top: 50%;
  }
  75% {
    left: 120%;
    top: 50%;
  }
  100% {
    height: 4px;
    left: 120%;
    top: 50%;
  }
}

@keyframes fireworkAnimateLeft {
  0% {
    left: 50%;
    top: 50%;
  }
  75% {
    left: -20%;
    top: 50%;
  }
  100% {
    height: 4px;
    left: -20%;
    top: 50%;
  }
}
@keyframes imageAnimate {
  0% {
    left: 50%;
    top: 50%;
  }
  75% {
    left: 100%;
    top: 0%;
  }
  100% {
    height: 4px;
    left: 100%;
    top: 0%;
  }
}
@keyframes imageCircleAnimate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    left: calc(50% - 30px);
  }
}
@keyframes speedCircleAnimate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    left: calc(50% - 30px);
  }
}
@keyframes imageOverlayAnimate {
  0% {
    width: 80px;
    height: 80px;
  }
  100% {
    left: 80px;
    width: 0;
  }
}
@keyframes speedImageOverlayAnimate {
  0% {
    left: calc(50% + 50px);
    top: 50%;
    width: 0;
    height: 0;
  }
  100% {
    left: 0;
    width: 80px;
    height: 80px;
  }
}
@keyframes scoreAnimate {
  30% {
    top: -60px;
  }
  75% {
    top: -60px;
  }
  100% {
    top: 15%;
  }
}
@keyframes speedAnimate {
  30% {
    top: -60px;
  }
  75% {
    top: -60px;
  }
  90% {
    top: 0%;
  }
}

@keyframes speedDisplay {
  to {
    visibility: visible;
  }
  100% {
    width: 50px;
    left: calc(50%);
    top: calc(50%);
  }
}
@keyframes circleAnimate {
  to {
    visibility: hidden;
  }
}

@keyframes showCircleAnimate {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(100%);
  }
}
