@import '../../variables.scss';
@import '../../shared.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray-color;
}

.left {
  display: flex;
  justify-content: flex-start;
}
.teamRespondentName {
  font-size: 12px;
  margin-left: 6px;
  margin-top: 6px;
}
.teamOverview {
  top: 35px;
  position: absolute;
  left: 70px;
  font-size: 16px;
  font-weight: bold;
}
.teamScore {
  font-size: 14px;
  font-weight: 100;
  margin-left: 5px;
}
.userIcon {
  width: 48px;
  height: 48px;
  vertical-align: middle;
  margin-right: 10px;
}

.overview {
  margin-left: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-weight: $font-weight-bold;
  font-size: 16px;
  max-width: 500px;
  @include ellipsis;
}

.profileName {
  font-weight: $font-weight-normal;
  font-size: $font-size-xs;
}

.score {
  font-size: 14px;
  color: $gray-color;
}

.right {
  display: flex;
}

.connectedRespondents {
  margin-right: 20px;
  padding: 4px 8px;
  background: #e5e5e5;
  display: flex;
}

.connectedRespondents div {
  margin-left: 10px;
  min-width: 30px;
  text-align: right;
}

@media screen and (max-width: 280px) {
  .name {
    max-width: 100px;
  }
}

@media screen and (min-width: 300px) and (max-width: 540px) {
  .name {
    max-width: 200px;
  }
}
