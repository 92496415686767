.mainContainer {
  height: 100%;
  position: relative;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  .mainContainer {
    width: 100%;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainContainer {
    width: 100%;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .mainContainer {
    width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
