.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #1b87e6;
  animation: showCircleAnimate 0.25s ease-in forwards;
}
.circleContent {
  animation: moveCircle 0.6s forwards;
  animation-delay: 0.8s;
  position: absolute;
  top: 50%;
  left: 50%;
  animation-iteration-count: 3;
}
.innerCircle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1b87e6;
}

.line {
  display: inline-block;
  width: 8px;
  height: 0;
  border-radius: 4px;
  background-color: #1b87e6;
  position: absolute;
  left: 50%;
  top: calc(50% + 4px);
  transform-origin: top center;
}

.lineRight {
  transform: rotate(-90deg);
  animation: lineRightAnimate 0.3s ease-in forwards;
  animation-delay: 0.2s;
}

.lineTop {
  transform: rotate(180deg);
  animation: lineAnimate 0.3s ease-in forwards;
  animation-delay: 0.2s;
}
.square {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 8px;
  background-color: #1b87e6;
}

.square1 {
  left: calc(50% - 27px);
  top: calc(50% - 37px);
  animation: showSquare 0.3s forwards;
  animation-delay: 0.2s;
  transform: rotate(-45deg);
}
.square2 {
  left: calc(50% + 29px);
  top: calc(50% - 39px);
  animation: showSquare 0.3s forwards;
  animation-delay: 0.2s;
  transform: rotate(45deg);
}
.square3 {
  left: 50%;
  top: calc(50% - 46px);
  animation: showSquare 0.3s forwards;
  animation-delay: 0.2s;
}
.square3Line {
  height: 8px;
  width: 20px;
  position: absolute;
  left: calc(50% - 6px);
  top: calc(50% - 53px);
  background-color: #1b87e6;
  border-radius: 4px;
  animation: expandCenter 0.3s ease-in forwards;
  animation-delay: 0.3s;
  visibility: hidden;
}

@keyframes lineAnimate {
  0% {
    height: 10px;
  }
  100% {
    height: 30px;
  }
}

@keyframes lineRightAnimate {
  0% {
    height: 10px;
  }
  100% {
    height: 20px;
  }
}

@keyframes showCircleAnimate {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(800%);
  }
}

@keyframes moveCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  95% {
    transform: rotate(15deg);
  }
  100% {
    transform:  rotate(0deg);
  }
}

@keyframes showSquare {
  0% {
    height: 0;
  }
  100% {
    height: 6px;
  }
}

@keyframes expandCenter {
  to {
    visibility: visible;
  }
  0% {
    clip-path: polygon(50% 100%, 50% 0, 50% 0, 50% 100%);
    -webkit-clip-path: polygon(50% 100%, 50% 0, 50% 0, 50% 100%);
  }
  100% {
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
    -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
  }
}
