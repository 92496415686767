.container {
  display: flex;
  flex-direction: column;
}
.backButton {
  position: absolute;
  top: 85px;
  font-size: 14px;
  cursor: pointer;
  left: 20px;
}
.backButton span {
  margin-left: 8px;
}

.backButton:hover span {
  border-bottom: 1px solid;
}
.title {
  justify-content: center;
  display: flex;
  font-size: 24px;
  line-height: 30px;
  color: #545e6b;
  font-weight: 500;
}
.message {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.letterTilesContainer {
  position: relative;
  height: 60px;
}

.letterTile {
  width: 50px;
  height: 50px;
  border: 2px solid #e53251;
  border-radius: 6px;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  cursor: pointer;
  position: absolute;
}

.correctTile {
  background-color: #3fca5a;
  border-color: #3fca5a;
  color: #fff;
}
.skipButton {
  font-size: 14px;
  margin: auto;
  margin-top: 72px;
  user-select: none;
  color: var(--tabColor, #1b87e6);
}
.skipButton:hover {
  cursor: pointer;
}
.li {
  font-size: 16px;
  text-align: center;
}

.correctMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lastCorrectWord {
  color: #3fca5a;
  font-weight: 700;
  font-size: 32px;
  margin: 20px 0 35px 0;
}
.correctWordCount {
  font-weight: 400;
  color: #545e6b;
  font-size: 18px;
  margin: 8px auto 64px auto;
}
.tipContainer {
  margin-top: 72px;
  opacity: 0.7;
  font-size: 14px;
}
.instructions {
  composes: tipContainer;
  visibility: hidden;
}
.tip {
  margin: 10px 0;
}

.lightbulb {
  color: #ffbb73;
}

@media only screen and (max-width: 400px) {
  .letterTilesContainer {
    position: relative;
    height: 40px;
    margin: 0 65px;
  }

  .letterTile {
    width: 30px;
    height: 30px;
    border: 1px solid #e53251;
    border-radius: 6px;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: 'absolute';
  }

  .correctTile {
    background-color: #3fca5a;
    color: #fff;
    border-color: #3fca5a;
  }
  .skipButton {
    font-size: 12px;
  }
  .li {
    font-size: 14px;
  }
}
