@import '../../../variables.scss';

.container {
  margin-top: 50px;
  position: relative;
  width: 100%;
  text-align: center;
}

.container input[type='text'] {
  border: none;
  border-bottom: 1px solid $little-light-gray-color;
  display: block;
  width: 100%;
  box-sizing: border-box;
  max-width: 343px;
  margin: auto;
  padding: 7px;
  background-color: #FFFFFF;
  color:  $text-color;;
  font-weight: $font-weight-normal;
  font-size: $font-size-md;
  text-align: center;
  border-radius: 0;
}

.container input[type='text']:focus {
  outline: none;
}

.container .line {
  height: 1px;
  width: 0px;
  background-color: $little-light-gray-color;
  display: inline-block;
  transition: 0.3s width ease-in-out;
  position: relative;
  top: -15px;
  max-width: 343px;
}

.container input[type='text']:focus + .line {
  width: 100%;
  background-color: $white-color;
  border-bottom: 1px solid $submit-btn-color;
}
