.defaultStyle {
  background-color: transparent;
  border: none;
  padding: 0;
  height: 0;
  color: inherit;
  font-size: inherit;
  font-style: inherit;
}
button:hover {
  cursor: pointer;
}

button:disabled {
  opacity: 0.8;
  cursor: default;
}

.spinner {
  margin-right: 5px;
}