@import '../../shared.scss';
@import '../../variables.scss';

.container {
  @extend %container-shared;
  @extend %slideIn !optional;
  text-align: center;
  position: relative;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
}

.livefeed_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60vh;
}

.questionTitle {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  color: $text-color;
  text-align: left;
  margin: 20px 0;
}
.getReadyText {
  height: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: 100;
  color: #a4a4a4;
}
