@import '../../../../variables.scss';
@import '../../../../shared.scss';

/**
 * z-index info
 * 9 - for top most card in the stack
 * 6 - for the second card in the stack
 * 3 - for the third card in the stack
 * 1 - for answer text row in the card
 * -1 - for image card div
 * 
 * -1 - for the swipe tutorial card when not in animation
 * 1000 - for dark overlay on entire screen
 * 2000 - for the swipe tutorial card when animation is in progress
 * 2010 - for thumb on swipe tutorial card when animation is in progress
 */

.container {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  height: 100%;
}

.cardsAndSwipeOptionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.cards {
  position: relative;
  width: 296px;
  height: 348px;
}

.cards > div:nth-child(2) {
  width: 100%;
  margin: 0 auto;
  z-index: 9;
}

.cards > div:nth-child(3) {
  width: 95%;
  margin: 16px auto 0;
  z-index: 6;
}

.cards > div:nth-child(4) {
  width: 90%;
  margin: 32px auto 0;
  z-index: 3;
}

.cards > div:nth-child(n + 5) {
  display: none;
}

.swipeTutorial {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 296px;
  height: 348px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  animation-name: move-card-left, move-card-right;
  animation-duration: 2s, 2s;
  animation-delay: 0s, 2s;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  padding: 16px;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.swipeTutorial > span:first-child {
  visibility: hidden;
  animation-name: card-text;
  animation-duration: 2s;
  animation-delay: 2s;
}

.swipeTutorial > span:last-child {
  visibility: hidden;
  animation-name: card-text;
  animation-duration: 2s;
  animation-delay: 0s;
  margin-left: auto;
}

.thumb {
  position: absolute;
  left: 115px;
  top: 150px;
  background-color: #fff;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  border: 4px solid $blue-color;
  margin: auto;
  animation-name: thumb-on-card;
  animation-duration: 4s;
  z-index: -1;
}

@keyframes move-card-left {
  from {
    transform: translateX(0) rotate(0);
    opacity: 0;
    background-color: #ea4650;
    visibility: visible;
    z-index: 2000;
  }

  50% {
    transform: translateX(-200px) rotate(15deg);
    opacity: 1;
    background-color: #ea4650;
    z-index: 2000;
  }

  to {
    transform: translateX(0) rotate(0);
    opacity: 0;
    background-color: #ea4650;
    visibility: hidden;
    z-index: 2000;
  }
}

@keyframes move-card-right {
  from {
    visibility: visible;
    transform: translateX(0) rotate(0);
    opacity: 0;
    background-color: #45b75b;
    z-index: 2000;
  }

  50% {
    transform: translateX(200px) rotate(-15deg);
    opacity: 1;
    background-color: #45b75b;
    z-index: 2000;
  }

  to {
    transform: translateX(0) rotate(0);
    opacity: 0;
    background-color: #45b75b;
    visibility: hidden;
    z-index: 2000;
  }
}

@keyframes card-text {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 1;
  }
}

@keyframes thumb-on-card {
  0% {
    border: 4px solid $blue-color;
    transform: translateX(0);
    z-index: 2010;
  }

  4% {
    border: 8px solid $blue-color;
  }

  25% {
    border: 8px solid $blue-color;
    transform: translateX(-200px);
  }

  42% {
    border: 8px solid $blue-color;
  }

  50% {
    border: 4px solid $blue-color;
    transform: translateX(0);
  }

  54% {
    border: 8px solid $blue-color;
  }

  75% {
    border: 8px solid $blue-color;
    transform: translateX(200px);
  }

  92% {
    border: 8px solid $blue-color;
  }

  100% {
    transform: translateX(0);
    border: 4px solid $blue-color;
    z-index: 2010;
  }
}

.card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: transform 0.5s ease, width 0.2s ease-in, margin 0.2s ease-in,
    z-index 0.2s ease-in;
}

.textCardStyles {
  padding: 16px;
  border: 1px solid #eee;
  box-shadow: 0px 2px 16px -8px rgba(0, 0, 0, 0.35);
  background: #fff;
}

.leftSwipeBtnContainer {
  margin: auto 48px auto 0;
}

.leftSwipeBtn {
  color: #ff7681;
  border-color: #ff7681 !important;
}

.leftSwipeBtn:hover {
  background-color: #ff7681;
  color: #fff;
}

.rightSwipeBtnContainer {
  margin: auto 0 auto 48px;
}

.rightSwipeBtn {
  color: #3fca7f;
  border-color: #3fca7f !important;
}

.rightSwipeBtn:hover {
  background-color: #3fca7f;
  color: #fff;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.swipeOptionText {
  max-width: 200px;
}
.allCardsSwipedContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.allCardsSwipedContainer .cardsSwipedText1,
.allCardsSwipedContainer .cardsSwipedText2 {
  color: #545e6b;
  font-size: $font-size-md;
}

.allCardsSwipedContainer .cardsSwipedText1 {
  margin: 30px 0 24px 0;
}

.blurEffectSecondCard {
  filter: blur(4px);
}

.blurEffectThirdCard {
  filter: blur(4px);
}

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.75);
  transition: 0.2s;
}

@media only screen and (max-width: 768px) {
  .cardsAndSwipeOptionsContainer {
    flex-direction: column;
  }

  .leftSwipeBtnContainer,
  .rightSwipeBtnContainer {
    display: none;
  }
}

@media only screen and (max-width: 400px) and (max-height: 700px) {
  .cardsAndSwipeOptionsContainer {
    justify-content: flex-start;
  }
}
