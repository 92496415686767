.container {
  align-items: center;
  display: flex;
  font-size: 16px;
  color: #545e6b;
  flex-grow: 1;
  justify-content: center;
  height: 70vh;
}

.innerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.welcomeMessage {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 200px;
}
.livePollSessionName {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
}