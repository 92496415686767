.container {
  display: flex;
  min-width: 350px;
  justify-content: space-around;
}
.imageDiv {
  margin-top: 15px;
  margin-bottom: 15px;
}
.statsText {
  margin-bottom: 15px;
  color: #555555;
  font-size: 16px;
}
.rightAnswersCountAndSpeedRank {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.rightAnswersCount,
.speedRankContainer {
  font-size: 24px;
  color: var(--main, #1b3380);
  display: flex;
  min-width: 110px;
}
.count {
  color: #555;
  font-size: 18px;
  text-align: left;
  padding-left: 5px;
}
.subText {
  font-size: 12px;
  margin-top: -2px;
}
.hoverIcon {
  width: 16px;
  top: -8px;
  left: 4px;
  position: relative;
}
.speedRank {
  color: #555;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.count span {
  font-weight: bold;
  font-size: 24px;
}
