.container {
  display: flex;
  align-items: center;
  color: #545E6B;
  font-weight: 300;
}

.flagIcon {
  margin-right: 6px;
  width: 15px;
  height: 15px;
}

@media only screen and (min-width: 992px) {
  .container {
    font-size: 18px;
  }
}