.container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.image {
  width: 120px;
  position: relative;
  transform-origin: center center;
  animation: a-image-animate 5s linear infinite;
}
.cloudRight {
  position: absolute;
  bottom: -10px;
  left: 180px;
  width: 80px;
  animation: cloudRightAnimate 5s linear infinite;
  z-index: 1;
}
.cloudLeft {
  position: absolute;
  top: -20px;
  left: -180px;
  width: 80px;
  animation: cloudLeftAnimate 5s linear infinite;
}
.overlayCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: white;
  z-index: 2;
}
.animate {
  animation: overlayCircleAnimate 0.5s forwards;
}
@keyframes overlayCircleAnimate {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 200%;
    height: 200%;
  }
}
@keyframes cloudRightAnimate {
  0% {
    left: 180px;
    opacity: 0;
  }
  33% {
    left: 60px;
    opacity: 0.6;
  }
  66% {
    left: -60px;
    opacity: 0.6;
  }
  100% {
    left: -180px;
    opacity: 0;
  }
}
@keyframes cloudLeftAnimate {
  0% {
    left: -180px;
    opacity: 0;
  }
  33% {
    left: -60px;
    opacity: 0.6;
  }
  66% {
    left: 60px;
    opacity: 0.6;
  }
  100% {
    left: 180px;
    opacity: 0;
  }
}
@keyframes a-image-animate {
  0% {
    transform: rotate(0);
    top: 0;
    left: 0;
  }
  20% {
    transform: rotate(25deg);
    top: 0;
    left: 0;
  }
  45% {
    transform: rotate(15deg);
    top: 15px;
    left: 0;
  }
  70% {
    transform: rotate(-15deg);
    top: 10px;
    left: 10px;
  }
  90% {
    transform: rotate(0deg);
    top: 0;
    left: 0;
  }
  100% {
    transform: rotate(0);
    top: 0;
    left: 0;
  }
}